@tailwind base;
@tailwind components;
@tailwind utilities;

@media screen and (max-width: 1200px) {
  html {
    font-size: 14px;
  }
}

:root {
  scroll-behavior: smooth;
  --primary-color: #0e0e0e;
  --secondary-color: #d6b560;
  --darkGrey-color: #282828;
  --lightGrey-color: #4e4e4e;
  --blueGrey-color: #b7b7b7;
  --error-color: #eb3b3b;
  --table-color: #434343;
  --customGreen-color: #1d8d70;
  --eb: #263F4C
}

@media (min-width: 768px) {
  :root {
    --toastify-toast-width: 500px !important;
  }
}

body {
  font-family: "Inter", sans-serif;
  background-color: var(--primary-color);
}

/* typography fonts */
h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  font-family: "Inter", sans-serif;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.scroll-blur::after {
  display: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  /* background: linear-gradient(to bottom, #23b3d2, #2faf91) !important; */
  background: var(--secondary-color) !important;
  border-radius: 1.375rem;
}

/* For Edge and other Chromium-based browsers */
input::-ms-reveal {
  display: none;
}

/* For WebKit browsers (like Chrome, Safari) */
input::-webkit-credentials-auto-fill-button {
  display: none;
}

/* ================================ Color classes ================================ */
.border-primary {
  border-color: var(--primary-color) !important;
}

.text-primary {
  color: var(--primary-color) !important;
}

.bg-primary {
  background-color: var(--primary-color) !important;
}

.accent-error {
  accent-color: var(--error-color) !important;
}

.border-error {
  border-color: var(--error-color) !important;
}

.text-error {
  color: var(--error-color) !important;
}

.bg-error {
  background-color: var(--error-color) !important;
}

.accent-secondary {
  accent-color: var(--secondary-color) !important;
}

.border-secondary {
  border-color: var(--secondary-color) !important;
}

.text-secondary {
  color: var(--secondary-color) !important;
}

.bg-secondary {
  background-color: var(--secondary-color) !important;
}

.accent-customGreen {
  accent-color: var(--customGreen-color) !important;
}

.border-customGreen {
  border-color: var(--customGreen-color) !important;
}

.text-customGreen {
  color: var(--customGreen-color) !important;
}

.bg-customGreen {
  background-color: var(--customGreen-color) !important;
}

.accent-darkGrey {
  accent-color: var(--darkGrey-color) !important;
}

.border-darkGrey {
  border-color: var(--darkGrey-color) !important;
}

.text-darkGrey {
  color: var(--darkGrey-color) !important;
}

.bg-darkGrey {
  background-color: var(--darkGrey-color) !important;
}

.border-lightGrey {
  border-color: var(--lightGrey-color) !important;
}

.ring-lightGrey {
  background: var(--lightGrey-color) !important;
}

.text-lightGrey {
  color: var(--lightGrey-color) !important;
}

.bg-lightGrey {
  background-color: var(--lightGrey-color) !important;
}

.border-blueGrey {
  border-color: var(--blueGrey-color) !important;
}

.text-blueGrey {
  color: var(--blueGrey-color) !important;
}

.bg-blueGrey {
  background-color: var(--blueGrey-color) !important;
}

.border-table {
  border-color: var(--table-color) !important;
}

.text-table {
  color: var(--table-color) !important;
}

.bg-table {
  background-color: var(--table-color) !important;
}

.border-eb {
  border-color: var(--eb) !important;
}

.text-eb {
  color: var(--eb) !important;
}

.bg-eb {
  background-color: var(--eb) !important;
}

/* ================================ Color classes ================================ */
.icon {
  fill: var(--blueGrey-color);
  stroke: var(--blueGrey-color);
  transition: fill 0.3s ease, stroke 0.3s ease;
}

.icon:hover {
  fill: var(--primary-color);
  stroke: var(--primary-color);
}

/* button:disabled{
  background-color: #ccc;
  background-image: none;
}

button:disabled:hover{
  background-color: #ccc;
  background-image: none;
} */

/* .theme-btn {
  @apply text-white rounded-lg sm: rounded-[10px] text-sm sm:text-base shadow hover:shadow-md focus:shadow-md focus:outline-none focus:ring-0 active:shadow-md active:text-white transition duration-150 ease-in-out flex items-center justify-center whitespace-nowrap font-semibold hover:bg-gradient-to-l bg-gradient-to-r from-[#2FC6A1] to-[#23B3D2];
} */

/* .rdrCalendarWrapper {
  background-color: var(--darkGrey-color) !important;
} */

@layer components {
  .theme-btn {
    @apply text-primary rounded-lg text-sm shadow transition duration-150 ease-in-out flex items-center justify-center whitespace-nowrap font-semibold bg-secondary;
  }

  @media (min-width: 640px) {
    .theme-btn {
      @apply rounded-[10px] text-base;
    }

    .theme-btn:hover {
      @apply shadow-md bg-gradient-to-l;
    }

    .theme-btn:active {
      @apply shadow-md;
    }

    .theme-btn:focus {
      @apply outline-none ring-0 shadow-md;
    }
  }

  .view-edit-btn {
    @apply px-5 py-[9.7px] text-white bg-lightGrey rounded-lg text-[11px] shadow transition duration-150 ease-in-out flex items-center justify-center whitespace-nowrap font-semibold;
  }

  @media (min-width: 640px) {
    .view-edit-btn {
      @apply rounded-[10px] text-base;
    }

    .view-edit-btn:hover {
      @apply shadow-md;
    }

    .view-edit-btn:active {
      @apply shadow-md;
    }

    .view-edit-btn:focus {
      @apply outline-none ring-0 shadow-md;
    }
  }

  .attach-file-btn {
    @apply px-5 py-[9.7px] text-secondary bg-darkGrey rounded-lg text-[11px] shadow transition duration-150 ease-in-out flex items-center justify-center whitespace-nowrap font-semibold;
  }

  @media (min-width: 640px) {
    .attach-file-btn {
      @apply rounded-[10px] text-base;
    }

    .attach-file-btn:hover {
      @apply shadow-md;
    }

    .attach-file-btn:active {
      @apply shadow-md;
    }

    .attach-file-btn:focus {
      @apply outline-none ring-0 shadow-md;
    }
  }

  .attach-file-btn-signup {
    @apply px-5 py-[9.7px] text-secondary bg-darkGrey border border-secondary rounded-lg text-[11px] shadow transition duration-150 ease-in-out flex items-center justify-center whitespace-nowrap font-semibold;
  }

  @media (min-width: 640px) {
    .attach-file-btn-signup {
      @apply rounded-[10px] text-base;
    }

    .attach-file-btn-signup:hover {
      @apply shadow-md;
    }

    .attach-file-btn-signup:active {
      @apply shadow-md;
    }

    .attach-file-btn-signup:focus {
      @apply outline-none ring-0 shadow-md;
    }
  }

  .modify-btn {
    @apply px-5 py-[9.7px] text-[#1D8D70] rounded-lg text-[11px] shadow transition duration-150 ease-in-out flex items-center justify-center whitespace-nowrap font-semibold bg-white;
  }

  @media (min-width: 640px) {
    .modify-btn {
      @apply rounded-[10px] text-base !bg-[#E0F5F0];
    }

    .modify-btn:hover {
      @apply shadow-md;
    }

    .modify-btn:active {
      @apply shadow-md text-white;
    }

    .modify-btn:focus {
      @apply outline-none ring-0 shadow-md;
    }
  }

  .dropdown-menu {
    @apply block w-full text-left px-4 py-2 text-sm bg-darkGrey text-blueGrey;
  }

  .dropdown-menu:hover {
    @apply bg-secondary text-primary;
  }
}

.text-head {
  @apply break-words min-w-[42%] w-36 sm:text-base text-sm text-white;
}

.text-detail {
  @apply break-words flex-1 xs:text-base text-sm font-semibold text-blueGrey;
}

.approve,
.resolved,
.resolved,
.in {
  /* @apply bg-[#E0F5F0] text-[#1D8D70]; */
  @apply !bg-[#1D8D70] !text-white;
}

.pending {
  /* @apply bg-[#FADEBA] text-[#CA7F19]; */
  @apply !bg-[#CA7F19] !text-white;
}

.billed {
  /* @apply bg-[#C9A2FC] text-[#512b83]; */
  @apply bg-[#691CCD] !text-white;
}

.feature,
.placed,
.accepted {
  /* @apply bg-[#E0F5F0] text-[#1D8D70]; */
  @apply bg-[#1D8D70] !text-white;
}

.confirm,
.confirmed {
  /* @apply bg-[#BED7FC] text-[#236DE0]; */
  @apply bg-[#236DE0] !text-white;
}

.commission,
.paid {
  /* @apply !bg-[#FCE8A2] !text-[#927100]; */
  @apply !bg-[#927100] !text-white;
}

.status-confirmed {
  @apply text-secondary border-[1.5px] border-secondary font-semibold;
}

.status-not-confirmed {
  @apply text-[#C00E0E] border-[1.5px] border-[#C00E0E] font-semibold;
}

.completed {
  @apply bg-[#686868] !text-white;
}

.no,
.show {
  /* @apply !bg-[#e3e3e3] !text-[#0a0a0a] w-full max-w-fit; */
  @apply !bg-[#787878] !text-white;
}

.expired,
.cancel,
.cancelled,
.not-approved {
  /* @apply !bg-[#FDD3D3] !text-[#C00E0E] w-full max-w-fit; */
  @apply !bg-[#C00E0E] !text-white w-full max-w-fit;
}

input[type="checkbox"]:focus {
  border: none !important;
  outline: none !important;
}

.toggle-checkbox {
  border-color: var(--lightGrey-color);
}

.toggle-checkbox:checked {
  right: 0;
  border-color: var(--secondary-color);
}

.toggle-checkbox:checked+.toggle-label {
  background: var(--secondary-color) !important;
}

.preloader-wrapper {
  position: relative;
}

.preloader-wrapper span {
  width: 25px;
  height: 25px;
  position: relative;
  background: #fff;
  float: left;
  margin-right: 10px;
  border-radius: 50%;
  animation-name: preloader;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

.preloader-sm span {
  width: 10px;
  height: 10px;
  margin-right: 3px;
}

.preloader-wrapper span:nth-child(1) {
  animation-delay: 0.15s;
}

.preloader-wrapper span:nth-child(2) {
  animation-delay: 0.3s;
}

.preloader-wrapper span:nth-child(3) {
  animation-delay: 0.45s;
}

/* date-picker css */

.react-datepicker__month-container {
  min-height: 200px;
}

.react-datepicker__month-text {
  padding: 10px;
}

.custom-datepicker {
  width: 100%;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.input__error__style {
  border: 1px solid #eb3b3b !important;
  border-left: 0.375rem solid #eb3b3b !important;
}

.custom__input-container {
  margin: 0 !important;
}

@keyframes preloader {
  0% {
    background-color: var(--secondary-color);
    transform: translateY(0);
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
  }

  50% {
    background-color: #fff;
    transform: translateY(-10px);
    box-shadow: 0 1.25rem 3px rgba(0, 0, 0, 0.15);
  }

  100% {
    background: var(--secondary-color);
    transform: translateY(0);
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
  }
}

select {
  /* reset */
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}

select.minimal {
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, transparent, transparent);
  background-position: calc(100% - 18px) calc(1em + 5px),
    calc(100% - 14px) calc(1em + 5px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
  /* outline: 1px solid #C0C0C0; */
}

select.minimal:focus {
  background-image: linear-gradient(45deg, green 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, green 50%),
    linear-gradient(to right, transparent, transparent);
  background-position: calc(100% - 14px) 1em, calc(100% - 18px) 1em,
    calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
  border-color: #2faf91;
  outline: 1px solid #2faf91;
}

textarea:focus {
  border-color: var(--secondary-color);
}

textarea {
  resize: none;
}

textarea::placeholder,
input::placeholder {
  color: var(--blueGrey-color) !important;
  font-size: 0.875rem !important;
}

input {
  color-scheme: dark !important;
  font-size: 0.875rem !important;
  color: #ffffff !important;
}

input:focus {
  border-color: var(--secondary-color) !important;
  outline: 1.5px solid var(--secondary-color);
}

.inputError:focus {
  border-color: var(--error-color) !important;
  outline: 1.5px solid var(--error-color) !important;
}

.input-right-icon svg {
  height: 0.75rem;
  width: 0.75rem;
}

.input-right-icon path {
  fill: #a1a1a1;
}

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

@media screen and (max-width: 1280px) {
  :root {
    font-size: 14px;
  }

  select.minimal {
    background-position: calc(100% - 11px) calc(1em + 2px),
      calc(100% - 7px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  }

  select.minimal:focus {
    background-position: calc(100% - 7px) 1em, calc(100% - 11px) 1em,
      calc(100% - 2.5em) 0.5em;
  }
}

.activePage {
  border-bottom: 2px solid var(--secondary-color);
  color: var(--secondary-color);
}

/* ScrollBar Style */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: darkgrey;
}

.secondActiveLink {
  background: var(--secondary-color);
  color: var(--primary-color);
  font-weight: 600;
}

.secondLink:hover {
  background: var(--lightGrey-color);
}

.payment-checkbox {
  position: relative;
  padding: 0 0.475rem;
}

.payment-checkbox label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 1.75rem;
  left: 0;
  position: absolute;
  top: 0;
  width: 1.75rem;
  transform: scale(0.6) translate(0%, -20%);
  padding: 0 10px;
}

.payment-checkbox label:after {
  border: 2px solid var(--secondary-color);
  border-top: none;
  border-right: none;
  content: "";
  height: 0.375rem;
  left: 0.438rem;
  opacity: 0;
  position: absolute;
  top: 0.5rem;
  transform: rotate(-45deg);
  width: 0.75rem;
}

@media screen and (max-width: 1023px) {
  .payment-checkbox label:after {
    height: 0.375rem;
    width: 0.85rem;
  }
}

@media screen and (max-width: 375px) {
  .payment-checkbox label:after {
    height: 0.575rem;
    width: 0.95rem;
  }
}

.payment-checkbox input[type="checkbox"] {
  visibility: hidden;
}

.payment-checkbox input[type="checkbox"]:checked+label {
  background-color: #fff;
  border-color: var(--secondary-color);
}

.payment-checkbox input[type="checkbox"]:checked+label:after {
  opacity: 1;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

/* for mozilla */
input[type="number"] {
  -moz-appearance: textfield;
}

body> :not(.Toastify__toast-container):not(.Toastify__toast-container--top-right):last-of-type,
div.custom__menu-portal,
div[class$="-MenuPortal2"],
div[class$="-MenuPortal"] {
  z-index: 9999 !important;
  touch-action: none;
}

.side-nav:hover {
  background: var(--darkGrey-color) !important;
  color: var(--blueGrey-color) !important;
}

.activeLink,
.activeLink:hover {
  background: var(--secondary-color) !important;
  color: var(--primary-color) !important;
  fill: var(--primary-color);
  stroke: var(--primary-color);
}

.MiniMenu:hover,
.MiniMenu:hover .icon {
  background: var(--secondary-color);
  color: var(--primary-color);
  fill: var(--primary-color);
  stroke: var(--primary-color);
}

.shadow-popup-card {
  box-shadow: rgb(0 0 0 / 24%) 0px 1px 10px;
}

.restaurant-card-shadow {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
}

.promotion-clipy {
  clip-path: polygon(0% 0%, 100% 0, 100% 100%, 49% 81%, 0% 100%);
}

.custom__value-container--is-multi {
  overflow-x: auto !important;
  flex-wrap: nowrap !important;
}

.custom__multi-value {
  min-width: fit-content !important;
}

.remove-indicator-dropdown#remove-indicator-dropdown [class*="IndicatorsContainer"][class*="IndicatorsContainer"],
.remove-indicator-dropdown#remove-indicator-dropdown [class*="indicatorSeparator"][class*="indicatorSeparator"],
.remove-indicator-dropdown#remove-indicator-dropdown [class*="control"][class*="control"]:nth-child(2),
.remove-indicator-dropdown#remove-indicator-dropdown .css-1wy0on6 {
  display: none !important;
}

.custom__multi-value__remove {
  background-color: #ffbdad !important;
  color: #de350b !important;
}

.custom__control:hover {
  border-color: var(--lightGrey-color) !important;
}

.react-tooltip-lite {
  background-color: var(--lightGrey-color);
  border: 1px solid var(--lightGrey-color);
  text-align: center;
  @apply shadow text-sm rounded-lg !max-w-[30rem];
}

@keyframes blink {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.7;
  }
}

.blink {
  animation: blink 1.5s infinite;
}

@keyframes tilt-shake {

  0%,
  100% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(-0.5deg);
    /* Tilt left */
  }

  50% {
    transform: rotate(0.5deg);
    /* Tilt right */
  }

  75% {
    transform: rotate(-0.5deg);
    /* Tilt left */
  }
}

.tilt-shake {
  animation: tilt-shake 1.5s infinite;
}

.tilt-shake-blink {
  animation: tilt-shake 1.5s infinite, blink 1.5s infinite;
}

@keyframes shakeWithPause {
  0% {
    transform: translateX(0);
  }

  10% {
    transform: translateX(-5px);
  }

  20% {
    transform: translateX(5px);
  }

  30% {
    transform: translateX(-5px);
  }

  40% {
    transform: translateX(5px);
  }

  50% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(0);
  }

  /* Pause between 50% and 100% */
}

.shake-blink {
  animation: shakeWithPause 1.8s ease-in-out infinite, blink 1.8s infinite;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(-5px);
  }

  50% {
    transform: translateX(5px);
  }

  75% {
    transform: translateX(-5px);
  }

  100% {
    transform: translateX(0);
  }
}

.shake-card {
  animation: shakeWithPause 1.2s ease-in-out infinite;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

/* Change the style for the day names (Sun, Mon, Tue, etc.) */
.react-datepicker__day-name {
  color: darkGrey !important;
}

/* Style for the time picker */
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 100%;
  background-color: #f8f9fa;
}

.react-datepicker-time__header {
  color: darkGrey !important;
}

/* Styles for enabled dates */
.react-datepicker__day.enabled-date {
  color: darkGrey;
}

/* Styles for disabled dates */
.react-datepicker__day.disabled-date {
  color: #000 !important;
  cursor: not-allowed !important;
}

.react-datepicker__calendar-icon {
  color: var(--secondary-color) !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  color: darkGrey;
  /* Blue color for time text */
  cursor: pointer;
  background-color: var(--darkGrey-color);
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  color: var(--secondary-color) !important;
  background-color: var(--lightGrey-color) !important;
}

/* Styling for the selected date */
.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  color: var(--secondary-color) !important;
  background-color: var(--lightGrey-color) !important;
}

.reward-clipy {
  clip-path: polygon(0 22%, 100% 22%, 100% 100%, 51% 69%, 0% 100%);
}

.custom-notification {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 20px;
  border-radius: 8px;
  z-index: 1000;
  text-align: center;
  width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.custom-notification h3 {
  margin: 0;
  font-size: 18px;
}

.custom-notification p {
  font-size: 14px;
  margin: 10px 0 0 0;
}

.hide__child__scroll .infinite-scroll-component__outerdiv ::-webkit-scrollbar {
  width: 0px !important;
  /* Hides the scrollbar */
  display: none;
  background: transparent;
  /* Ensures no visible thumb */
}

.high-zindex-datepicker {
  z-index: 99999 !important;
}