.react-datepicker__triangle {
    display: none;
}


/* Month Picker */
/* . .react-datepicker__month-text--keyboard-selected, */
.react-datepicker__month {
    color: var(--blueGrey-color) !important;
    background: var(--darkGrey-color) !important;
}

.react-datepicker__close-icon::after {
    background-color: var(--secondary-color) !important;
    color: var(--primary-color) !important;
    font-weight: 900;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
    border: none;
    border-radius: 7px;
    color: var(--primary-color) !important;
    background: var(--secondary-color) !important;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
    border: none;
    border-radius: 7px;
    color: var(--primary-color) !important;
    background: var(--secondary-color) !important;
}

.react-datepicker-popper .react-datepicker__navigation {
    padding-top: 0px !important;
    height: 28px !important;
    color: var(--blueGrey-color) !important;
    background: var(--darkGrey-color) !important;
}

.react-datepicker {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.189);
    border: none !important;
    background: var(--darkGrey-color) !important;
}

.react-datepicker__header,
.react-datepicker-year-header {
    border-bottom: solid 1px var(--lightGrey-color) !important;
    color: var(--blueGrey-color) !important;
    background: var(--darkGrey-color) !important;
}

.react-datepicker__current-month {
    color: var(--blueGrey-color) !important;
}

.react-datepicker__day.react-datepicker__day--today {
    border-radius: 7px;
    border: solid 2px var(--brand) !important;
    background-color: white !important;
    color: var(--blueGrey-color) !important;
    width: 30px;
    height: 30px;
}

.react-datepicker__day.react-datepicker__day--selected {
    border: none;
    border-radius: 7px;
    background-color: var(--darkGrey-color) !important;
    color: white;
}

.react-datepicker__month-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-area: 1rem;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
    background-color: var(--secondary-color) !important;
    color: var(--primary-color) !important;
}


/* Date Range Selector */
.rdrCalendarWrapper {
    background-color: var(--darkGrey-color) !important;
    color: var(--blueGrey-color) !important;
}

.rdrDateDisplayWrapper,
.rdrMonthAndYearPickers,
.rdrNextPrevButton,
.rdrDefinedRangesWrapper {
    background-color: var(--darkGrey-color) !important;
}

.rdrStaticRange label {
    color: #fff !important;
}

.rdrDay {
    background-color: var(--darkGrey-color) !important;
}

.rdrDayNumber span,
.rdrDayNumber:hover span,
.rdrDayNumber:focus span {
    color: #fff !important;
}

.rdrEndEdge+.rdrDayNumber:hover span,
.rdrStartEdge+.rdrDayNumber:hover span,
.rdrInRange+.rdrDayNumber:hover span {
    color: #fff !important;
}

.rdrEndEdge+.rdrDayNumber span,
.rdrStartEdge+.rdrDayNumber span,
.rdrInRange+.rdrDayNumber span {
    color: var(--primary-color) !important;
    font-weight: 600 !important;
}

.rdrEndEdge+.rdrDayNumber span,
.rdrDayToday .rdrDayNumber span {
    border-color: #fff !important;
    color: var(--primary-color) !important;
    font-weight: 600 !important;
}

.rdrDaySelected span,
.rdrDaySelected:hover span,
.rdrDaySelected:focus span {
    background-color: var(--darkGrey-color) !important;
}

.rdrDayNumber {
    color: #fff !important;
}

.rdrDayHovered {
    overflow: hidden;
    background-color: transparent !important;
}

.rdrDayDisabled span {
    color: var(--lightGrey-color) !important;
}

.rdrDayPassive {
    background-color: var(--darkGrey-color) !important;
}

.rdrDayPassive .rdrDayNumber span,
.rdrDayPassive .rdrDayNumber span {
    color: var(--lightGrey-color) !important;
    background-color: var(--darkGrey-color) !important;
}

.rdrMonthPicker select,
.rdrYearPicker select {
    background-color: var(--darkGrey-color) !important;
    color: #fff !important;
}

/* Button Design */
.rdrPprevButton,
.rdrNextButton {
    background-color: var(--darkGrey-color) !important;
    height: 2.5rem !important;
    color: #fff !important;
}

.rdrNextButton i {
    border-color: transparent transparent transparent var(--secondary-color) !important;
}

.rdrPprevButton i {
    border-color: transparent var(--secondary-color) transparent transparent !important;
}

.rdrDayStartPreview,
.rdrDayInPreview,
.rdrDayEndPreview {
    background-color: transparent !important;
    color: #fff !important;
}

.rdrDayStartOfMonth span,
.rdrDayEndOfMonth span,
.rdrDayStartOfWeek span,
.rdrDayEndOfWeek span {
    border-radius: 10000 !important;
    overflow: hidden;
}

.rdrDayFocused span {
    background-color: var(--darkGrey-color) !important;
    overflow: hidden;
}

.rdrDateDisplayItem input {
    background-color: var(--lightGrey-color) !important;
    border-radius: 3px !important;
    color: #fff !important;
}

.rdrDateDisplayItem button {
    background-color: var(--lightGrey-color) !important;
    border-radius: 3px !important;
    color: #fff !important;
}

.rdrDay .rdrEndEdge~.rdrDayNumber span,
.rdrDay .rdrInRange~.rdrDayNumber span,
.rdrDay .rdrStartEdge~.rdrDayNumber span {
    color: var(--primary-color) !important;
    font-weight: 600 !important;
}

.rdrDayToday:not(.rdrEndEdge).rdrDayNumber span {
    background-color: var(--secondary-color) !important;
    color: var(--primary-color) !important;
}

.rdrDayToday:not(.rdrDayPassive) .rdrInRange~.rdrDayNumber span:after,
.rdrDayToday:not(.rdrDayPassive) .rdrStartEdge~.rdrDayNumber span:after,
.rdrDayToday:not(.rdrDayPassive) .rdrEndEdge~.rdrDayNumber span:after,
.rdrDayToday:not(.rdrDayPassive) .rdrSelected~.rdrDayNumber span:after {
    background-color: var(--darkGrey-color) !important;
}

.rdrDayToday:not(.rdrInRange) .rdrDayNumber span {
    color: var(--secondary-color) !important;
}

.rdrDayToday .rdrEndEdge~.rdrDayNumber span {
    color: var(--primary-color) !important;
}

.rdrDayToday:not(.rdrInRange) .rdrDayNumber span:after {
    background-color: var(--secondary-color) !important;
}

.react-datepicker__tab-loop .react-datepicker-popper{
    /* position: fixed !important; */
    position: absolute !important;
    
}